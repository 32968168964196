<template>
<transition name="slide-fade">
    <v-row ref="floatingView" class="component-card" v-draggable>
        <v-col cols="11" class="title-container  mb-0">
            <slot v-if="isOpen" name="title" />
            <slot v-else name="close-title" />
        </v-col>
        <v-col cols="1" class="py-0">
            <span class="btn-close float-right" v-if="isOpen">
                <v-icon @click="closeCard" large class="float-right" color="#2DB9D1">expand_less</v-icon>
            </span>

            <span class="btn-close float-right" v-else>
                <v-icon @click="openCard" large class="float-right" color="#2DB9D1">expand_more</v-icon>
            </span>
        </v-col>
        <div ref="floatingViewContainer" class="body-container">
            <transition name="slide-fade">
                <slot v-if="isOpen" name="body" />
            </transition>
        </div>
    </v-row>
</transition>
</template>

<script>
export default {
    name: 'floating-view',
    props: {
        items: { type: Array, default: () => [] },
    },
    data() {
        return {
            isOpen : false,
        }
    },
    watch: {
        items(newValue, oldValue) {
            if(this.isOpen)
                this.openCard();
        }
    },
    methods: {
        openCard() {
            this.isOpen = true;
            this.$refs.floatingViewContainer.style.visibility   = 'visible';
            let largo                                           = ( this.items.length * 72)+222;
            this.$refs.floatingView.style.height                = largo + 'px';
        },
        closeCard() {
            this.isOpen = false;

            setTimeout(() => {
                this.$refs.floatingView.style.height                = '0px';
                this.$refs.floatingViewContainer.style.visibility   = 'hidden';
            }, 200);
        },
    }
}
</script>

<style scoped>
    .component-card {
        width: 500px !important;
        max-height: 759px !important;
        min-height: 98px !important;
        padding-top: 28px;
        padding-left: 17px;
        padding-right: 10px;

        border-radius: 20px;
        background: #FFFFFF;

        /* Add shadows to create the "card" effect */
        box-shadow: 0px 4px 10px #C4C4C4;
        transition: 0.3s;

        z-index: 1;
        position: fixed;
        right: 10px;
        top:200px;
    }

    /* On mouse-over, add a deeper shadow */
    .component-card:hover {
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    }
    .btn-close {
        display: inline-block;
    }
    .close-color {
        color: #96999A !important;
    }
    .close-size {
        font-size: 16px !important;
        cursor: pointer;
    }
    .title-container {
        display: inline;
        max-height: 74px;
    }
    .body-container {
        width: 500px !important;
    }

    .body-container > div{
        padding-top:0px !important;
    }

    .slide-fade-enter-active {
        transition: all .8s ease;
    }
    .slide-fade-leave-active {
        transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter, .slide-fade-leave-to {
        transform: translateX(10px);
        opacity: 0;
    }
</style>